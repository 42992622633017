@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #FAF8F8;
  --foreground: #171717;
  --sidebar-bg: #FAF8F8;
  --main-bg: #FAF8F8;
  --code-bg: #f4f4f4;
  --code-border: #E5E5E5;
  --node-text: #000000;
  --header-one: #161618;
  --code-text: #333333;
  --internal-link-color: #4a5568;
  --border-color: #e5e7eb;
  --header-bg: #f3f4f6;
  --header-text: #111827;
}

.dark {
  --background: #161618;
  --foreground: #e2e8f0;
  --sidebar-bg: #161618;
  --main-bg: #161618;
  --code-bg: #1e1e1e;
  --code-border: #333333;
  --node-text: #ffffff;
  --header-one: #e2e8f0;
  --code-text: #f8f8f2;
  --internal-link-color: #e2e8f0;
  --border-color: #374151;
  --header-bg: #1f2937;
  --header-text: #f3f4f6;
}

body {
  background-color: var(--background);
  color: var(--foreground);
  transition: background-color 0.3s ease, color 0.3s ease;
  will-change: background-color, color;
}

li:has(a) {
  margin-bottom: 0.5em;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

/* Logo */
@keyframes nameLogoMove {
  0% {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
  }
  to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
  }
}

.nameLogo {
  position: relative;
  animation: nameLogoMove 0.3s;
  will-change: transform, opacity;
}

@keyframes nameLogoMove2 {
  0% {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
  }
  to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
  }
}

.nameLogo2 {
  position: relative;
  animation: nameLogoMove2 0.3s;
  will-change: transform, opacity;
}

/* 기존 스타일 아래에 추가 */

.node:hover {
  cursor: pointer;
}

.sidebar svg {
  border: 1px solid var(--code-border);
  border-radius: 1em;
}

/* 코드 블록 스타일링 */
pre {
  position: relative;
  background-color: var(--code-bg);
  border-radius: 4px;
  padding: 1em;
  margin: 1em 0;
  overflow-x: auto;
  border: 1px solid var(--code-border);
}

pre code {
  display: table;
  min-width: 100%;
  counter-reset: line;
  color: var(--code-text);
}

pre code .table-row {
  display: table-row;
}

pre code .table-cell {
  display: table-cell;
}

pre code .table-cell:first-child {
  padding-right: 1em;
  user-select: none;
}

/* Prism.js 테마 스타일 오버라이드 */
pre[class*="language-"] {
  background: var(--code-bg);
  color: var(--code-text);
}

code[class*="language-"],
pre[class*="language-"] {
  text-shadow: none;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #6a9955;
}

.token.punctuation {
  color: var(--code-text);
}

.token.namespace {
  opacity: .7;
}

.token.property,
.token.tag,
.token.constant,
.token.symbol,
.token.deleted {
  color: #569cd6;
}

.token.boolean,
.token.number {
  color: #b5cea8;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #ce9178;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
  color: #848484;
}

.token.atrule,
.token.attr-value,
.token.function,
.token.class-name {
  color: #c7c728;
}

.token.keyword {
  color: #569cd6;
}

.token.regex,
.token.important {
  color: #d16969;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

/* 다크 모드에서의 코드 블록 스타일 */
.dark pre[class*="language-"] {
  background: #282a36;
}

.dark code[class*="language-"],
.dark pre[class*="language-"] {
  color: #f8f8f2;
}

/* 나머지 스타일 유지 */
/* 복사 버튼 스타일 */
.copy-button {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: var(--code-text);
}

.dark .copy-button {
  background-color: rgba(255, 255, 255, 0.1);
}

/* 제목 스타일링 */
h1, h2, h3, h4, h5, h6 {
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  font-family: "Schibsted Grotesk", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
}

h1 {
  font-size: 2em;
  font-weight: 800;
  color: #DD7878;
}

h2 {
  font-size: 1.75em;
  font-weight: 600;
  color: #FE640C;
  padding-bottom: 0.2em;
  border-bottom: 2px solid #FE640C;
}

h3 {
  font-size: 1.5em;
  font-weight: 550;
  color: #41B658;
}

h4 {
  font-size: 1.25em;
  font-weight: 500;
  color: #42BFC0;
}

h5 {
  font-size: 1.15em;
  font-weight: 450;
  color: #7650ff;
}

.callout > svg {
  width: 1.1em;
  height: 1.1em;
}

/* 콜아웃 스타일 수정 */
.callout {
  margin: 1em 0;
  padding: 1em;
  border-radius: 4px;
  border-left: 4px solid;
}

.callout-header {
  display: flex;
  align-items: center;
  margin-bottom: 0em;
}

.callout-icon-wrapper {
  margin-right: 0.5em;
}

.callout-title {
  font-weight: bold;
}

.callout-content {
  margin-top: 0;
}

/* 콜아웃 내 여백 조정 */
.callout-content > p:last-child {
  margin-bottom: 0;
}

/* 접을 수 있는 콜아웃을 위한 스타일 */
.callout-content.open {
  max-height: 1000px; /* 적절한 값으로 조정 */
  transition: max-height 0.3s ease-in-out;
}

.callout-content.open > p:first-child {
  margin-top: 0.3em;
}


.callout-toggle {
  margin-left: auto;
  transition: transform 0.3s ease;
}

.callout-toggle.rotate-180 {
  transform: rotate(180deg);
}

/* 타입별 스타일 */
.callout-note {
  background-color: #e7f5ff;
  border-color: #74c0fc;
}

.callout-abstract {
  background-color: #e3fafc;
  border-color: #66d9e8;
}

.callout-success {
  background-color: #ebfbee;
  border-color: #51cf66;
}

.callout-info {
  background-color: #e3fafc;
  border-color: #3bc9db;
}

.callout-tip {
  background-color: #fff9db;
  border-color: #fcc419;
}

.callout-warning {
  background-color: #fff4e6;
  border-color: #ffa94d;
}

.callout-fail, .callout-error {
  background-color: #fff5f5;
  border-color: #ff6b6b;
}

.callout-bug {
  background-color: #f8f0fc;
  border-color: #da77f2;
}

.callout-example {
  background-color: #f4fce3;
  border-color: #a9e34b;
}

.callout-quote {
  background-color: #f1f3f5;
  border-color: #adb5bd;
}

.callout-faq {
  background-color: #f3f0ff;
  border-color: #7950f2;
}

/* 다크 모드 스타일 */
.dark .callout-note {
  background-color: #1864ab;
  border-color: #74c0fc;
}

.dark .callout-abstract {
  background-color: #0b7285;
  border-color: #66d9e8;
}

.dark .callout-success {
  background-color: #2b8a3e;
  border-color: #51cf66;
}

.dark .callout-info {
  background-color: #0b7285;
  border-color: #3bc9db;
}

.dark .callout-tip {
  background-color: #e67700;
  border-color: #fcc419;
}

.dark .callout-warning {
  background-color: #d9480f;
  border-color: #ffa94d;
}

.dark .callout-fail, .dark .callout-error {
  background-color: #c92a2a;
  border-color: #ff6b6b;
}

.dark .callout-bug {
  background-color: #862e9c;
  border-color: #da77f2;
}

.dark .callout-example {
  background-color: #5c940d;
  border-color: #a9e34b;
}

.dark .callout-quote {
  background-color: #495057;
  border-color: #adb5bd;
}

.dark .callout-faq {
  background-color: #5f3dc4;
  border-color: #7950f2;
}

/* 일반 텍스트 스타일 */
p, li {
  margin-bottom: 0.3em;
  line-height: 1.2;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
}

div {
  line-height: 1.2;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
}

/* 콜아웃 아이콘 스타일 */
.callout-icon {
  width: 1.3em;
  height: 1.3em;
}

/* 다크 모드 스타일 */
.dark .bg-blue-100 {
  background-color: #1a2942;
  color: #4299e1;
}

.dark .bg-green-100 {
  background-color: #1c3329;
  color: #38a169;
}

.dark .bg-yellow-100 {
  background-color: #3d3424;
  color: #d69e2e;
}

article {
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

/* 사이드바와 메인 컨텐츠 영역 스타일 */
.sidebar {
  background-color: var(--sidebar-bg);
  transition: transform 0.3s ease, background-color 0.3s ease;
  will-change: transform, background-color;
}

.main-content {
  background-color: var(--main-bg);
  transition: background-color 0.3s ease;
  will-change: background-color;
}

/* 본문 자 간격 조정 */
p, li {
  font-weight: 400;
  line-height: 1.6;
  font-size: 1em;
}

.callout li {
  margin-bottom: 0;
}

.sidebar li {
  /* margin-bottom: 0.2em; */
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate-360 {
  animation: rotate360 0.5s linear;
}

@keyframes dialRotate {
  0% {
    transform: rotate(0deg) translateX(0) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(20px) rotate(-360deg);
  }
}

.dial-rotate {
  animation: dialRotate 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
}

@keyframes iconEnter {
  0% {
    transform: translate(-100%, 100%) scale(0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
}

@keyframes iconExit {
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(100%, 100%) scale(0);
    opacity: 0;
  }
}

.icon-enter {
  animation: iconEnter 0.5s forwards;
}

.icon-exit {
  animation: iconExit 0.5s forwards;
}

/* 기존 콜아웃 스타일 제거 또는 수정 */
.callout {
  /* 새로운 스타일 정의 */
}

/* blockquote 스타일 재정의 */
blockquote.callout {
  /* 새로운 스타일 정의 */
}

/* 콜아웃 타입 스타일 */
.callout-note { border-color: #448aff; background-color: #e3f2fd; }
.callout-abstract { border-color: #00bcd4; background-color: #e0f7fa; }
.callout-info { border-color: #00b8d4; background-color: #e0f7fa; }
.callout-tip { border-color: #00bfa5; background-color: #e0f2f1; }
.callout-success { border-color: #4caf50; background-color: #e8f5e9; }
.callout-question { border-color: #64dd17; background-color: #f1f8e9; }
.callout-warning { border-color: #ff9100; background-color: #fff3e0; }
.callout-fail { border-color: #ff5252; background-color: #ffebee; }
.callout-error { border-color: #ff1744; background-color: #ffebee; }
.callout-bug { border-color: #f50057; background-color: #fce4ec; }
.callout-example { border-color: #651fff; background-color: #f3e5f5; }
.callout-quote { border-color: #9e9e9e; background-color: #f5f5f5; }
.callout-faq { border-color: #304ffe; background-color: #e8eaf6; }

/* 기존 스타일 유지 */

.callout-content {
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out, visibility 0.3s ease-out;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  max-height: 0;
}

.callout-content.open {
  opacity: 1;
  visibility: visible;
}

.callout-toggle {
  transition: transform 0.3s ease;
}

.callout-toggle.rotate-180 {
  transform: rotate(180deg);
}

.callout-content p {
  margin: 0.5em 0;
}

.callout-content p:first-child {
  margin-top: 0;
}

.callout-content p:last-child {
  margin-bottom: 0;
}

/* 기존 스타일 유지 */

/* 본문 리스트 스타일 복원 */
.prose ul:not(.sidebar-list):not(.toc-list) {
  list-style-type: disc;
  padding-left: 1.5em;
}

.prose ol:not(.sidebar-list):not(.toc-list) {
  list-style-type: decimal;
  padding-left: 1.5em;
}

.prose ul:not(.sidebar-list):not(.toc-list) > li,
.prose ol:not(.sidebar-list):not(.toc-list) > li {
  /* padding-left: 0.5em; */
}

/* 다크 모드에서의 리스트 스타일 */
.dark .prose ul:not(.sidebar-list):not(.toc-list) > li::marker,
.dark .prose ol:not(.sidebar-list):not(.toc-list) > li::marker {
  color: #9CA3AF;
}

/* 인라인 코드 스타일 추가 */
code:not(pre code) {
  background-color: rgba(194, 194, 194, 0.2);
  border-radius: 4px;
  padding: 2px 4px;
}

pre, code {
  font-family: "IBM Plex Mono", ui-monospace, SFMono-Regular, SF Mono, Menlo, monospace !important;
}

/* 코드 블록 내 줄 간격 조정 */
pre code div {
  height: 1.2rem !important; /* 기존 1.5rem에서 줄임 */
  line-height: 1.2;
}

/* 코드 블록의 줄 번호 높이도 맞춤 */
pre .flex-none div {
  height: 1.2rem !important;
}

/* 구분선(hr) 스타일 추가 */
hr {
  margin: 2em 0;
  border: none;
  border-top: 1px solid var(--code-border);
}

/* 내부 링크 스타일 */
.internal-link {
  color: var(--internal-link-color);
}

.internal-link:hover {
  text-decoration: underline;
}

/* 스크롤바 숨기기 */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;     /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, Opera */
}